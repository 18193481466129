<template>
  <Select v-model="type" @on-change="selectChange">
    <Option
      v-for="item in typeList.filter((t) => !hiddenWhole || t.label !== '全部')"
      :value="item.value"
      :key="item.value"
      >{{ item.label }}</Option
    >
  </Select>
</template>

<script>
export default {
  props: {
    value: {},
    hiddenWhole: {
      default: false,
    },
  },
  data() {
    return {
      type: "",
      typeList: [
        {
          value: 2,
          label: "所有",
        },
        {
          value: 0,
          label: "未处理",
        },
        {
          value: 1,
          label: "处理中",
        },
      ],
    };
  },
  methods: {
    selectChange() {
      this.$emit("input", this.type);
    },
  },
  mounted() {
    let ind = this.typeList.findIndex((ele) => ele.value === this.value);
    if (ind >= 0) {
      return;
    }
    this.$emit("input", this.typeList[this.hiddenWhole ? 1 : 0].value);
  },
  watch: {
    value: {
      handler() {
        this.type = this.value;
      },
      immediate: true,
    },
  },
};
</script>

<style>
</style>